import { createContext, useEffect, useState } from "react";
import * as api from "./api"
import toast from "react-hot-toast"

export const ApiContext = createContext({
  stage: null
})

export const ApiContextWrapper = (props) => {
  const [ stage, setStage ] = useState(null)

  useEffect(() => {
    api.getActiveStage().then((res) => setStage(res.data))
      .catch((err) => toast.error("Error getting stage"))
  }, [])

  const value = {
    stage
  }

  return (
    <ApiContext.Provider value={value}>
      {props.children}
    </ApiContext.Provider>
  )
}